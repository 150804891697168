<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="1250"
        persistent
      >
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Nuevo Gasto</v-toolbar>
            <v-container>
              <v-progress-linear
                v-if="loading"
                :loading="loading"
                indeterminate
              ></v-progress-linear>
              <v-form ref="form" v-model="valid" :lazy-validation="false">
                <v-row>
                  <v-col cols="12" md="4">
                    <project-field v-model="projectId"></project-field>
                    <span caption class="caption red--text">{{ errorProject }}</span>
                  </v-col>
                  <v-col cols="12" md="4">
                    <company-field
                      v-model="companyId"
                      @value="(val) => (companyId = val)"
                    ></company-field>
                    <span caption class="caption red--text">{{ errorCompany }}</span>
                  </v-col>
                  <v-col cols="12" md="4">
                    <project-type-detail-field
                      v-model="detailType"
                      :rules="onlyRequired"
                    ></project-type-detail-field>
                    <span caption class="caption red--text">{{ errorType }}</span>
                  </v-col>
                  <v-col cols="12" md="4">
                    <catalog-field
                      :title="'Tipo Documento'"
                      :name="'REFERENCIA'"
                      v-model="body.document"
                    ></catalog-field>
                    <span caption class="caption mt0 red--text">{{
                      errorDocumentType
                    }}</span>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      label="No. de Documento"
                      v-model="body.document_code"
                      type="text"
                      :rules="onlyRequired"
                    ></v-text-field>
                    <span caption class="caption mt0 red--text">{{ errorDocument }}</span>
                  </v-col>
                  <v-col cols="12" md="4">
                    <date-field
                      label="Fecha del documento"
                      v-model="body.date_document"
                    ></date-field>
                    <span caption class="caption mt0 red--text">{{ errorDate }}</span>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Descripción"
                      type="text"
                      counter
                      maxlength="250"
                      :rules="descRules"
                      v-model="body.description"
                    ></v-text-field>
                    <span caption class="caption mt0 red--text">{{
                      errorDescription
                    }}</span>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Cantidad"
                      type="number"
                      step="any"
                      v-model="body.quantity"
                      :rules="amountRules"
                    ></v-text-field>
                    <span caption class="caption mt0 red--text">{{ errorQuantity }}</span>
                  </v-col>
                  <v-col cols="12" md="3">
                    <catalog-field
                      :title="'Unidad'"
                      :name="'MEDIDA'"
                      v-model="body.unit"
                    ></catalog-field>
                    <span caption class="caption mt0 red--text">{{ errorUnit }}</span>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Precio"
                      type="number"
                      step="any"
                      v-model="body.price"
                      :rules="amountRules"
                    ></v-text-field>
                    <span caption class="caption mt0 red--text">{{ errorPrice }}</span>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Total"
                      type="number"
                      step="any"
                      disabled
                      v-model="body.amount"
                      :rules="amountRules"
                    ></v-text-field>
                    <span caption class="caption mt0 red--text">{{ errorAmount }}</span>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="warning" @click="resetForm()"> Cancelar </v-btn>
                  <v-btn
                    color="success"
                    @click="create()"
                    :disabled="!valid"
                    :loading="loading"
                  >
                    Registrar
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-container>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";
export default {
  components: {
    DateField: () => import("@/components/fields/DateField"),
    projectField: () => import("@/components/fields/ProjectField"),
    projectTypeDetailField: () => import("@/components/fields/ProyectTypeField"),
    catalogField: () => import("@/components/fields/CatalogField"),
    companyField: () => import("@/components/fields/CompanyField"),
  },
  props: {
    dialog: { type: Boolean, required: true },
  },
  data() {
    return {
      companyId:'',
      reset: false,
      valid: false,
      loading: false,
      projectId: {},
      detailType: {},
      body: {
        project_id: 0,
        type_id: 0,
        document: "",
        document_code: "",
        date_document: "",
        company_id: 0,
        description: "",
        quantity: 0,
        unit: "",
        price: 0,
        amount: 0,
      },
      //form rules
      descRules: [rules.required, rules.minLength(10), rules.maxLength(250)],
      onlyRequired: [rules.required],
      amountRules: [rules.required, rules.minValue(0.01)],
      // Errors messages
      errorProject: undefined,
      errorCompany: undefined,
      errorType: undefined,
      errorDocumentType: undefined,
      errorDocument: undefined,
      errorDate: undefined,
      errorDescription: undefined,
      errorQuantity: undefined,
      errorUnit: undefined,
      errorPrice: undefined,
      errorAmount: undefined,
    };
  },
  watch: {
    companyId(val) {
      if (typeof val === "object") {
        this.body.company_id = this.companyId.id;
      }else{
        this.body.company_id = this.companyId;
      }
    },
    projectId(val) {
      if (typeof val === "object") {
        this.body.project_id = this.projectId.code;
      }
    },
    detailType(val) {
      if (typeof val === "object") {
        this.body.type_id = this.detailType.id;
      }
    },
    errorUnit(val) {
      console.log(val);
    },
    body: {
      handler(val) {
        if (val.quantity > 0 && val.quantity != "" && val.price > 0 && val.price != "") {
          val.amount = Number(Number(val.quantity) * Number(val.price)).toFixed(2);
        }
      },
      deep: true,
    },
  },
  methods: {
    create() {
      this.resetMessageError();
      this.loading = true;
      requests.post("api/project/detail-project/", this.body).then((response) => {
        if (response.status == 201) {
          this.resetForm();
          this.$toasted.global.info({
            message: "Registro creado",
          });
          this.resetForm();
          this.$emit("saved");
          this.$emit("close");
        } else if (response.status == 400) {
          if ("project_id" in response.data) {
            this.errorProject = response.data.project_id[0];
          }
          if ("company_id" in response.data) {
            this.errorCompany = response.data.company_id[0];
          }
          if ("type_id" in response.data) {
            this.errorType = response.data.type_id[0];
          }
          if ("document_code" in response.data) {
            this.errorDocument = response.data.document_code[0];
          }
          if ("document" in response.data) {
            this.errorDocumentType = response.data.document[0];
          }
          if ("date_document" in response.data) {
            this.errorDate = response.data.date_document[0];
          }
          if ("description" in response.data) {
            this.errorDescription = response.data.description[0];
          }
          if ("quantity" in response.data) {
            this.errorQuantity = response.data.quantity[0];
          }
          if ("unit" in response.data) {
            this.errorUnit = response.data.unit[0];
          }
          if ("price" in response.data) {
            this.errorPrice = response.data.price[0];
          }
          if ("amount" in response.data) {
            this.errorAmount = response.data.amount[0];
          }
        }
        this.loading = false;
        // }
      });
    },
    resetMessageError() {
      this.errorProject = undefined;
      this.errorCompany = undefined;
      this.errorType = undefined;
      this.errorDocumentType = undefined;
      this.errorDocument = undefined;
      this.errorDate = undefined;
      this.errorDescription = undefined;
      this.errorQuantity = undefined;
      this.errorUnit = undefined;
      this.errorPrice = undefined;
      this.errorAmount = undefined;
    },
    resetForm() {
      this.reset = false;
      this.valid = false;
      this.loading = false;
      this.projectId = {};
      this.detailType = {};
      this.body = {
        project_id: 0,
        type_id: 0,
        document: "",
        document_code: "",
        date_document: "",
        description: "",
        quantity: 0,
        unit: "",
        price: 0,
        amount: 0,
      };
      this.$refs.form.reset();
      this.resetMessageError();
      this.$emit("close");
    },
  },
};
</script>
